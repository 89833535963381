<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <v-row class="mh-special-title-row">
          <v-card-title
            style="width: 100%"
            class="text-lg-h4 mh-page-title text-md-h5 justify-center pb-2 flex-row justify-lg-space-between"
          >
            <span class="mr-2">{{ pageData.title }} </span>
            <help-btn :new-version="true"></help-btn>
            <v-spacer></v-spacer>

            <v-btn-toggle
              v-model="toggle_period"
              mandatory
              dense
              data-html2canvas-ignore
            >
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Вчера
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Сегодня
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Неделя
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Месяц
              </v-btn>
              <v-btn
                elevation="0"
                class="mh-noactive-toggle"
                :active-class="'white black--text mh-active-toggle'"
              >
                Год
              </v-btn>
            </v-btn-toggle>
          </v-card-title>
        </v-row>

        <v-card outlined v-if="loading === false" class="mt-4">
          <v-container>
            <v-row>
              <v-col class="col-12 col-lg-8">
                <v-col class="col-12">
                  <v-card-title
                    style="width: 100%"
                    class="pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pb-2 flex-row text-wrap justify-lg-space-between"
                  >
                    <v-col class="col-12 col-md-7 col-xl-9">
                      Популярность новостного сайта ГТРК в регионах на 1000
                      населения
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-switch
                        color="primary"
                        class="pr-2"
                        value
                        label="Плитка"
                        v-model="toggle_map_type"
                      ></v-switch>
                      <v-menu offset-y v-if="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation="0"
                            max-width="40"
                            min-width="40"
                            class="ml-4"
                            style="text-transform: none !important"
                            v-bind="attrs"
                            v-on="on"
                            data-html2canvas-ignore
                          >
                            <v-icon small>mdi-download</v-icon></v-btn
                          >
                        </template>
                        <v-list dense>
                          <v-list-item
                            dense
                            link
                            @click="toExcel"
                            v-if="!isAlternate"
                          >
                            <v-list-item-title
                              >Таблица (xlsx)</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item dense link @click="saveChartToPng">
                            <v-list-item-title
                              >Изображение (png)</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-title>
                  <v-row v-if="false">
                    <v-col>
                      <span style="line-height: 20px" class="pl-2 caption"
                        ><v-icon small class="pr-1 mt-n1"
                          >mdi-clock-outline</v-icon
                        >Выпуск новостей</span
                      >
                      <v-slider
                        v-if="false"
                        color="rgba(0, 0, 0, 0.18)"
                        thumb-color="secondary"
                        track-color="rgba(0, 0, 0, 0.18)"
                        :disabled="disableField"
                        v-model="detalisationStep"
                        :tick-labels="
                          isReachOrSan ? tickLabelsReach : tickLabels
                        "
                        step="1"
                        :min="isReachOrSan ? 6 : 1"
                        max="10"
                        hide-details
                        tick-size="0"
                      >
                      </v-slider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="toggle_map_type">
                      <highcharts :options="drawChartOptionsTiles"></highcharts>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="!toggle_map_type">
                      <highcharts
                        :constructorType="'mapChart'"
                        :options="drawChartOptionsMap"
                      ></highcharts>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div id="comparison_slider" :style="'width: 100%'"></div>
                      <div v-if="false" class="chartHelper mt-4">
                        <v-icon small color="#FFA91B" class="pr-1"
                          >mdi-lightbulb</v-icon
                        >
                        Настройте тепловое окрашивание блока телепередачи в
                        зависимости от среднего значения показателя за выбранный
                        период.
                        <v-icon class="ml-2" small @click="hideTip"
                          >mdi-close</v-icon
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="regionStr"
                    class="orange mt-6"
                    justify="space-between"
                    style="min-height: 58px"
                  >
                    <v-col cols="auto" style="line-height: 38px"
                      ><h4>Выбран регион:{{ regionStr }}</h4>
                    </v-col>
                    <v-col align="end">
                      <v-btn
                        elevation="0"
                        max-width="204"
                        min-width="204"
                        style="
                          text-transform: none !important;
                          border: 1px solid rgba(0, 0, 0, 0.87) !important;
                        "
                        @click="region_chip = false"
                        data-html2canvas-ignore
                      >
                        Смотреть всю Россию</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="col-12">
                  <v-card-title
                    style="width: 100%"
                    class="pt-0 mt-0 pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pb-2 flex-row text-wrap justify-lg-space-between"
                  >
                    <v-col class="col-12 col-md-7 col-xl-9">
                      Самые читаемые новости
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn-toggle
                        v-model="toggle_news_type"
                        mandatory
                        dense
                        data-html2canvas-ignore
                      >
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Показы
                        </v-btn>
                        <v-btn
                          elevation="0"
                          class="mh-noactive-toggle"
                          :active-class="'white black--text mh-active-toggle'"
                        >
                          Устройства
                        </v-btn>
                      </v-btn-toggle>
                      <v-menu offset-y v-if="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            elevation="0"
                            max-width="40"
                            min-width="40"
                            class="ml-4"
                            style="text-transform: none !important"
                            v-bind="attrs"
                            v-on="on"
                            data-html2canvas-ignore
                          >
                            <v-icon small>mdi-download</v-icon></v-btn
                          >
                        </template>
                        <v-list dense>
                          <v-list-item
                            dense
                            link
                            @click="toExcel"
                            v-if="!isAlternate"
                          >
                            <v-list-item-title
                              >Таблица (xlsx)</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item dense link @click="saveChartToPng">
                            <v-list-item-title
                              >Изображение (png)</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-card-title>
                  <div class="mh-tabs-div" v-if="bookmarks.length > 0"></div>
                  <v-tabs
                    v-if="bookmarks.length > 0"
                    v-model="currentBookmark"
                    class="mh-demo-tabs"
                    style="margin-bottom: 12px"
                  >
                    <v-tab
                      v-for="item in bookmarks"
                      :key="item"
                      class="mh-tab body-2"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <v-row v-if="news_data.length && toggle_news_type == 0">
                    <div style="width: 100%" class="mt-4 ml-4 mr-4 mb-4">
                      <v-col cols="12" v-if="toggle_news_type == 0">
                        <highcharts
                          ref="hcShare"
                          :options="newsShare"
                        ></highcharts>
                      </v-col>

                      <v-col cols="12">
                        <div class="chartHelper mt-0">
                          <v-icon small color="#FFA91B" class="pr-1"
                            >mdi-lightbulb</v-icon
                          >
                          Наиболее популярные новостные сюжеты по суммарному
                          числу прочтений на сайтах ГТРК выбранного региона за
                          выбранный период

                          <v-icon
                            class="ml-2"
                            v-if="false"
                            small
                            @click="hideTip"
                            >mdi-close</v-icon
                          >
                        </div>
                      </v-col>
                    </div>
                  </v-row>
                  <v-row v-if="news_data.length && toggle_news_type == 1">
                    <div
                      style="width: 100%"
                      class="ml-4 mr-4 mb-4 mh-border-block"
                    >
                      <v-col cols="12" v-if="toggle_news_type == 1">
                        <highcharts :options="newsDevices"></highcharts>
                      </v-col>
                      <v-col cols="12">
                        <div class="chartHelper mt-0">
                          <v-icon small color="#FFA91B" class="pr-1"
                            >mdi-lightbulb</v-icon
                          >
                          Наиболее популярные новостные сюжеты по суммарному
                          числу прочтений на сайтах ГТРК выбранного региона за
                          выбранный период

                          <v-icon class="ml-2" small @click="hideTip"
                            >mdi-close</v-icon
                          >
                        </div>
                      </v-col>
                    </div>
                  </v-row>
                  <base-loader
                    v-if="news_data.length == 0 && bookmarks.length > 0"
                  >
                  </base-loader> </v-col
              ></v-col>
              <v-col class="col-12 col-lg-4">
                <v-col class="col-12">
                  <v-row>
                    <div
                      style="width: 100%; overflow: hidden"
                      class="ml-4 mr-4 mb-4 mt-4 mh-border-block"
                    >
                      <v-col cols="12">
                        <v-card-title
                          style="width: 100%"
                          class="pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pa-0 flex-row text-wrap justify-lg-space-between"
                        >
                          <v-col class="col-auto"> Упоминаемые темы </v-col>
                          <v-spacer></v-spacer>
                          <v-col cols="auto">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  elevation="0"
                                  max-width="40"
                                  min-width="40"
                                  style="text-transform: none !important"
                                  v-bind="attrs"
                                  v-on="on"
                                  data-html2canvas-ignore
                                >
                                  <v-icon small>mdi-download</v-icon></v-btn
                                >
                              </template>
                              <v-list dense>
                                <v-list-item
                                  dense
                                  link
                                  @click="toExcel"
                                  v-if="!isAlternate"
                                >
                                  <v-list-item-title
                                    >Таблица (xlsx)</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item dense link @click="saveChartToPng">
                                  <v-list-item-title
                                    >Изображение (png)</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-card-title>
                      </v-col>
                      <v-col cols="12">
                        <highcharts :options="tagsChart"></highcharts>
                      </v-col>
                    </div>
                    <div
                      style="
                        width: 100%;
                        overflow: hidden;
                        background: white;
                        z-index: 2;
                      "
                      class="ml-4 mr-4 mb-4 mt-4 mh-border-block"
                    >
                      <v-col cols="12">
                        <v-card-title
                          style="width: 100%"
                          class="pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pa-0 flex-row text-wrap justify-lg-space-between"
                        >
                          <v-col class="col-auto">
                            Типы используемых устройств</v-col
                          >
                        </v-card-title>
                        <v-row justify="center">
                          <v-col
                            cols="auto"
                            align="center"
                            v-if="pcPercent > 0"
                            align-self="end"
                            style="position: relative"
                          >
                            <p class="device-percent">{{ pcPercent }}%</p>
                            <svg
                              width="110"
                              height="93"
                              viewBox="0 0 110 93"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.496094 8C0.496094 3.58172 4.07782 0 8.49609 0H101.496C105.914 0 109.496 3.58172 109.496 8V64C109.496 68.4183 105.914 72 101.496 72H64.4961V85H81.4961C83.7052 85 85.4961 86.7909 85.4961 89C85.4961 91.2091 83.7052 93 81.4961 93H28.4961C26.287 93 24.4961 91.2091 24.4961 89C24.4961 86.7909 26.287 85 28.4961 85H45.4961V72H8.49609C4.07781 72 0.496094 68.4183 0.496094 64V8Z"
                                :fill="SPECIAL_INTERNET_COLORS[0]"
                              />
                            </svg>
                          </v-col>
                          <v-col
                            cols="auto"
                            align="center"
                            v-if="tabletPercent > 0"
                            align-self="end"
                            style="position: relative"
                          >
                            <p class="device-percent">{{ tabletPercent }}%</p>
                            <svg
                              width="54"
                              height="77"
                              viewBox="0 0 54 77"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M53.5125 5C53.5125 2.23858 51.2739 2.00101e-06 48.5125 0H5.4961C2.73467 0 0.496097 2.23858 0.496097 5L0.496094 72C0.496094 74.7614 2.73467 77 5.4961 77H48.5125C51.2739 77 53.5125 74.7614 53.5125 72L53.5125 5ZM27.0044 6.31189C27.7016 6.31189 28.2667 5.74675 28.2667 5.0496C28.2667 4.35245 27.7016 3.7873 27.0044 3.7873C26.3073 3.7873 25.7421 4.35245 25.7421 5.0496C25.7421 5.74675 26.3073 6.31189 27.0044 6.31189Z"
                                :fill="SPECIAL_INTERNET_COLORS[1]"
                              />
                            </svg>
                          </v-col>

                          <v-col
                            cols="auto"
                            align="center"
                            v-if="mobilePercent > 0"
                            align-self="end"
                            style="position: relative"
                          >
                            <p class="device-percent">{{ mobilePercent }}%</p>

                            <svg
                              width="31"
                              height="54"
                              viewBox="0 0 31 54"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.503906"
                                width="30"
                                height="54"
                                rx="3"
                                :fill="SPECIAL_INTERNET_COLORS[2]"
                              />
                            </svg>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col style="position: relative">
                            <div class="mh-tabs-div"></div>
                            <v-tabs
                              v-model="tabDevices"
                              class="mh-demo-tabs"
                              style="margin-bottom: 12px"
                            >
                              <v-tab
                                v-for="item in tabitems"
                                :key="item"
                                class="mh-tab body-2"
                              >
                                {{ item }}
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tabDevices">
                              <v-tab-item>
                                <highcharts
                                  :options="smartphonesShare"
                                ></highcharts>
                              </v-tab-item>
                              <v-tab-item>
                                <highcharts
                                  :options="tabletsShare"
                                ></highcharts>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <div
                      style="
                        width: 100%;
                        overflow: hidden;
                        background: white;
                        z-index: 2;
                      "
                      class="ml-4 mr-4 mb-4 mt-4 mh-border-block"
                    >
                      <v-col cols="12">
                        <v-card-title
                          style="width: 100%"
                          class="pl-0 text-lg-h5 mh-page-title text-md-h6 justify-center pa-0 flex-row text-wrap justify-lg-space-between"
                        >
                          <v-col class="col-auto">
                            {{ selected_geo > 0 ? "ТОП" : "ТОП-10" }} городов по
                            доле посещения сайтов ГТРК</v-col
                          >
                        </v-card-title>
                        <v-row>
                          <v-col style="position: relative">
                            <highcharts :options="topCities"></highcharts>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col style="position: relative">
                            <highcharts :options="cityTypes"></highcharts>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card outlined class="mt-2" :height="'100%'" v-if="loading === true">
          <base-loader></base-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { Chart } from "highcharts-vue";
import mapMarkup from "../../public/jsons/russia.json";
import proj4 from "proj4";
import noUiSlider from "nouislider";
import { CONSTS } from "../services/constants";
import wNumb from "wnumb";
import BaseLoader from "@/components/BaseLoader.vue";
import HelpBtn from "@/components/HelpBtn.vue";
// eslint-disable-next-line no-unused-vars
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

export default {
  name: "SpecialInternet",
  components: {
    HelpBtn,
    BaseLoader,
    highcharts: Chart,
  },

  data() {
    return {
      regionStr: "",
      loading: true,
      tagsData: [],
      news_data: [],
      selected_geo: -1,
      ranges: [-90, -70, -60, -50],
      city_types: [],
      cities: [],
      tabDevices: 0,
      tabitems: ["Смартфоны", "Планшеты"],
      devices: {
        devices: [],
        mobile: [],
        tablet: [],
      },
      region_chip: true,
      mapData: [],
      currentBookmark: 0,
      bookmarks: [],
      options: {
        tvRegions: [],
        tvRegionsPreData: [
          {
            region_id: "1",
            hc_key: "ru-bl",
            short: "Бел",
            region: "Белгородская область",
            y: "4",
            x: "7",
            mh_city_id: "224",
            population: "1041310",
            source: "ГТРК Белгород",
          },
          {
            region_id: "2",
            hc_key: "ru-br",
            short: "Бря",
            region: "Брянская область",
            y: "2",
            x: "5",
            mh_city_id: "461",
            population: "833197",
            source: "ГТРК Брянск",
          },
          {
            region_id: "3",
            hc_key: "ru-vl",
            short: "Влад",
            region: "Владимирская область",
            y: "5",
            x: "4",
            mh_city_id: "225",
            population: "1049234",
            source: "ГТРК Владимир",
          },
          {
            region_id: "4",
            hc_key: "ru-vr",
            short: "Вор",
            region: "Воронежская область",
            y: "5",
            x: "7",
            mh_city_id: "188",
            population: "1567046",
            source: "ГТРК Воронеж",
          },
          {
            region_id: "5",
            hc_key: "ru-iv",
            short: "Иван",
            region: "Ивановская область",
            y: "5",
            x: "3",
            mh_city_id: "375",
            population: "807364",
            source: "ГТРК Ивтерерадио",
          },
          {
            region_id: "6",
            hc_key: "ru-kg",
            short: "Калу",
            region: "Калужская область",
            y: "2",
            x: "4",
            mh_city_id: "471",
            population: "758763",
            source: "ГТРК Калуга",
          },
          {
            region_id: "7",
            hc_key: "ru-kt",
            short: "Костр",
            region: "Костромская область",
            y: "6",
            x: "3",
            mh_city_id: "246",
            population: "459063",
            source: "ГТРК Кострома",
          },
          {
            region_id: "8",
            hc_key: "ru-ks",
            short: "Курск",
            region: "Курская область",
            y: "3",
            x: "6",
            mh_city_id: "215",
            population: "752748",
            source: "ГТРК Курск",
          },
          {
            region_id: "9",
            hc_key: "ru-lp",
            short: "Лип",
            region: "Липецкая область",
            y: "4",
            x: "6",
            mh_city_id: "210",
            population: "729043",
            source: "ГТРК Липецк",
          },
          {
            region_id: "10",
            hc_key: "ru-2509",
            short: "МО",
            region: "Московская область",
            y: "3",
            x: "4",
            mh_city_id: "178",
            population: "6296406",
            source: "Вести-Москва",
          },
          {
            region_id: "11",
            hc_key: "ru-ol",
            short: "Орл",
            region: "Орловская область",
            y: "3",
            x: "5",
            mh_city_id: "412",
            population: "483431",
            source: "ГТРК Орел",
          },
          {
            region_id: "12",
            hc_key: "ru-rz",
            short: "Ряз",
            region: "Рязанская область",
            y: "5",
            x: "5",
            mh_city_id: "206",
            population: "793275",
            source: "ГТРК Ока",
          },
          {
            region_id: "13",
            hc_key: "ru-sm",
            short: "Смол",
            region: "Смоленская область",
            y: "1",
            x: "4",
            mh_city_id: "428",
            population: "663281",
            source: "ГТРК Смоленск",
          },
          {
            region_id: "14",
            hc_key: "ru-tb",
            short: "Там",
            region: "Тамбовская область",
            y: "5",
            x: "6",
            mh_city_id: "242",
            population: "611901",
            source: "ГТРК Тамбов",
          },
          {
            region_id: "15",
            hc_key: "ru-tv",
            short: "Твер",
            region: "Тверская область",
            y: "3",
            x: "3",
            mh_city_id: "220",
            population: "950071",
            source: "ГТРК Тверь",
          },
          {
            region_id: "16",
            hc_key: "ru-tl",
            short: "Туо",
            region: "Тульская область",
            y: "4",
            x: "5",
            mh_city_id: "212",
            population: "1082882",
            source: "ГТРК Тула",
          },
          {
            region_id: "17",
            hc_key: "ru-ys",
            short: "Яро",
            region: "Ярославская область",
            y: "4",
            x: "3",
            mh_city_id: "199",
            population: "1011966",
            source: "ГТРК Ярославия",
          },
          {
            region_id: "18",
            hc_key: "ru-ms",
            short: "Мск",
            region: "Город федерального значения Москва",
            y: "4",
            x: "4",
            mh_city_id: "178",
            population: "12455682",
            source: "Вести-Москва",
          },
          {
            region_id: "19",
            hc_key: "ru-ki",
            short: "Кар",
            region: "Республика Карелия",
            y: "3",
            x: "1",
            mh_city_id: "245",
            population: "494545",
            source: "ГТРК Карелия",
          },
          {
            region_id: "20",
            hc_key: "ru-ko",
            short: "Коми",
            region: "Республика Коми",
            y: "9",
            x: "2",
            mh_city_id: "513",
            population: "637072",
            source: "ГТРК Коми Гор",
          },
          {
            region_id: "21",
            hc_key: "ru-ar",
            short: "Арх",
            region: "Архангельская область",
            y: "8",
            x: "2",
            mh_city_id: "458",
            population: "888896",
            source: "ГТРК Поморье",
          },
          {
            region_id: "22",
            hc_key: "ru-vo",
            short: "Волог",
            region: "Вологодская область",
            y: "4",
            x: "2",
            mh_city_id: "463",
            population: "837220",
            source: "ГТРК Вологда",
          },
          {
            region_id: "23",
            hc_key: "ru-kn",
            short: "Кали",
            region: "Калининградская область",
            y: "0",
            x: "2",
            mh_city_id: "378",
            population: "792070",
            source: "ГТРК Калининград",
          },
          {
            region_id: "24",
            hc_key: "ru-ln",
            short: "Лен",
            region: "Ленинградская область",
            y: "2",
            x: "2",
            mh_city_id: "126",
            population: "1274208",
            source: "ГТРК Санкт-Петербург",
          },
          {
            region_id: "25",
            hc_key: "ru-mm",
            short: "Мур",
            region: "Мурманская область",
            y: "3",
            x: "0",
            mh_city_id: "495",
            population: "675190",
            source: "ГТРК Мурман",
          },
          {
            region_id: "26",
            hc_key: "ru-ng",
            short: "Новг",
            region: "Новгородская область",
            y: "3",
            x: "2",
            mh_city_id: "264",
            population: "424638",
            source: "ГТРК Славия",
          },
          {
            region_id: "27",
            hc_key: "ru-ps",
            short: "Пск",
            region: "Псковская область",
            y: "2",
            x: "3",
            mh_city_id: "418",
            population: "440010",
            source: "ГТРК Псков",
          },
          {
            region_id: "28",
            hc_key: "ru-sp",
            short: "Спб",
            region: "Город федерального значения Санкт-Петербург",
            y: "2",
            x: "1",
            mh_city_id: "126",
            population: "5384342",
            source: "ГТРК Санкт-Петербург",
          },
          {
            region_id: "29",
            hc_key: "ru-nn",
            short: "Нен",
            region: "Ненецкий автономный округ",
            y: "9",
            x: "1",
            mh_city_id: null,
            population: "32948",
            source: "",
          },
          {
            region_id: "30",
            hc_key: "ru-ad",
            short: "Адыг",
            region: "Республика Адыгея",
            y: "3",
            x: "8",
            mh_city_id: "988",
            population: "217846",
            source: "ГТРК Адыгея",
          },
          {
            region_id: "31",
            hc_key: "ru-kl",
            short: "Калм",
            region: "Республика Калмыкия",
            y: "6",
            x: "8",
            mh_city_id: "992",
            population: "124616",
            source: "ГТРК Калмыкия",
          },
          {
            region_id: "32",
            hc_key: "ru-kr",
            short: "Крым",
            region: "Республика Крым",
            y: "2",
            x: "8",
            mh_city_id: "230",
            population: "966232",
            source: "ГТРК Таврида",
          },
          {
            region_id: "33",
            hc_key: "ru-kd",
            short: "Крд",
            region: "Краснодарский край",
            y: "4",
            x: "8",
            mh_city_id: "223",
            population: "3159354",
            source: "ГТРК Кубань",
          },
          {
            region_id: "34",
            hc_key: "ru-as",
            short: "Астр",
            region: "Астраханская область",
            y: "7",
            x: "8",
            mh_city_id: "207",
            population: "664210",
            source: "ГТРК Лотос",
          },
          {
            region_id: "35",
            hc_key: "ru-vg",
            short: "Волг",
            region: "Волгоградская область",
            y: "6",
            x: "7",
            mh_city_id: "189",
            population: "1915787",
            source: "ГТРК Волгоград-ТРВ",
          },
          {
            region_id: "36",
            hc_key: "ru-ro",
            short: "Рост",
            region: "Ростовская область",
            y: "5",
            x: "8",
            mh_city_id: "185",
            population: "2850790",
            source: "ГТРК Дон-ТР",
          },
          {
            region_id: "37",
            hc_key: "ru-sc",
            short: "Сев",
            region: "город федерального значения Севастополь",
            y: "2",
            x: "9",
            mh_city_id: "129",
            population: "479394",
            source: "ГТРК Севастополь",
          },
          {
            region_id: "38",
            hc_key: "ru-da",
            short: "Даг",
            region: "Республика Дагестан",
            y: "7",
            x: "9",
            mh_city_id: "996",
            population: "1419922",
            source: "ГТРК Дагестан",
          },
          {
            region_id: "39",
            hc_key: "ru-in",
            short: "Инг",
            region: "Республика Ингушетия",
            y: "6",
            x: "10",
            mh_city_id: "997",
            population: "287222",
            source: "ГТРК Ингушетия",
          },
          {
            region_id: "40",
            hc_key: "ru-kb",
            short: "Кбр",
            region: "Кабардино-Балкарская Республика",
            y: "4",
            x: "10",
            mh_city_id: "991",
            population: "452074",
            source: "ГТРК Кабардино-Балкария",
          },
          {
            region_id: "41",
            hc_key: "ru-kc",
            short: "Кчр",
            region: "Карачаево-Черкесская Республика",
            y: "4",
            x: "9",
            mh_city_id: "993",
            population: "199645",
            source: "ГТРК Карачаеко-Черкессия",
          },
          {
            region_id: "42",
            hc_key: "ru-no",
            short: "Осет",
            region: "Республика Северная Осетия",
            y: "5",
            x: "10",
            mh_city_id: "994",
            population: "445605",
            source: "ГТРК Алания",
          },
          {
            region_id: "43",
            hc_key: "ru-cn",
            short: "Чеч",
            region: "Чеченская Республика",
            y: "6",
            x: "9",
            mh_city_id: null,
            population: "569730",
            source: "ГТРК Вайнах",
          },
          {
            region_id: "44",
            hc_key: "ru-st",
            short: "Став",
            region: "Ставропольский край",
            y: "5",
            x: "9",
            mh_city_id: "995",
            population: "1652707",
            source: "ГТРК Ставрополье",
          },
          {
            region_id: "45",
            hc_key: "ru-bk",
            short: "Бшкр",
            region: "Республика Башкортостан",
            y: "9",
            x: "5",
            mh_city_id: "986",
            population: "2510951",
            source: "ГТРК Башкортостан",
          },
          {
            region_id: "46",
            hc_key: "ru-me",
            short: "Мари",
            region: "Республика Марий Эл",
            y: "7",
            x: "3",
            mh_city_id: "978",
            population: "455577",
            source: "ГТРК Марий Эл",
          },
          {
            region_id: "47",
            hc_key: "ru-mr",
            short: "Морд",
            region: "Республика Мордовия",
            y: "6",
            x: "5",
            mh_city_id: "979",
            population: "498861",
            source: "ГТРК Мордовия",
          },
          {
            region_id: "48",
            hc_key: "ru-tt",
            short: "Тат",
            region: "Республика Татарстан",
            y: "8",
            x: "4",
            mh_city_id: "181",
            population: "2994450",
            source: "ГТРК Татарстан",
          },
          {
            region_id: "49",
            hc_key: "ru-ud",
            short: "Удм",
            region: "Удмуртская Республика",
            y: "9",
            x: "4",
            mh_city_id: "194",
            population: "988602",
            source: "ГТРК Удмуртия",
          },
          {
            region_id: "50",
            hc_key: "ru-cv",
            short: "Чув",
            region: "Чувашская Республика",
            y: "7",
            x: "4",
            mh_city_id: "985",
            population: "768955",
            source: "ГТРК Чувашия",
          },
          {
            region_id: "51",
            hc_key: "ru-pe",
            short: "Перм",
            region: "Пермский край",
            y: "9",
            x: "3",
            mh_city_id: "187",
            population: "1957552",
            source: "ГТРК Пермь",
          },
          {
            region_id: "52",
            hc_key: "ru-kv",
            short: "Кир",
            region: "Кировская область",
            y: "8",
            x: "3",
            mh_city_id: "473",
            population: "977563",
            source: "ГТРК Вятка",
          },
          {
            region_id: "53",
            hc_key: "ru-nz",
            short: "Ниж",
            region: "Нижегородская область",
            y: "6",
            x: "4",
            mh_city_id: "180",
            population: "2534833",
            source: "ГТРК Нижний Новгород",
          },
          {
            region_id: "54",
            hc_key: "ru-ob",
            short: "Орен",
            region: "Оренбургская область",
            y: "8",
            x: "6",
            mh_city_id: "980",
            population: "1182109",
            source: "ГТРК Оренбург",
          },
          {
            region_id: "55",
            hc_key: "ru-pz",
            short: "Пенз",
            region: "Пензенская область",
            y: "6",
            x: "6",
            mh_city_id: "981",
            population: "892116",
            source: "ГТРК Пенза",
          },
          {
            region_id: "56",
            hc_key: "ru-sa",
            short: "Сам",
            region: "Самарская область",
            y: "8",
            x: "5",
            mh_city_id: "184",
            population: "2514764",
            source: "ГТРК Самара",
          },
          {
            region_id: "57",
            hc_key: "ru-sr",
            short: "Сар",
            region: "Саратовская область",
            y: "7",
            x: "6",
            mh_city_id: "982",
            population: "1813408",
            source: "ГТРК Саратов",
          },
          {
            region_id: "58",
            hc_key: "ru-ul",
            short: "Уль",
            region: "Ульяновская область",
            y: "7",
            x: "5",
            mh_city_id: "197",
            population: "927075",
            source: "ГТРК Волга",
          },
          {
            region_id: "59",
            hc_key: "ru-ku",
            short: "Кург",
            region: "Курганская область",
            y: "11",
            x: "4",
            mh_city_id: "233",
            population: "510098",
            source: "ГТРК Курган",
          },
          {
            region_id: "60",
            hc_key: "ru-sv",
            short: "Свер",
            region: "Свердловская область",
            y: "10",
            x: "4",
            mh_city_id: "36",
            population: "3650725",
            source: "ГТРК Урал",
          },
          {
            region_id: "61",
            hc_key: "ru-ty",
            short: "Тюм",
            region: "Тюменская область",
            y: "11",
            x: "3",
            mh_city_id: "192",
            population: "3068004",
            source: "ГТРК Регион-Тюмень",
          },
          {
            region_id: "62",
            hc_key: "ru-cl",
            short: "Чел",
            region: "Челябинская область",
            y: "10",
            x: "5",
            mh_city_id: "182",
            population: "2846995",
            source: "ГТРК Южный Урал",
          },
          {
            region_id: "63",
            hc_key: "ru-km",
            short: "Хант",
            region: "Ханты-Мансийский автономный округ — Югра",
            y: "10",
            x: "3",
            mh_city_id: "539",
            population: "1563020",
            source: "ГТРК Югория",
          },
          {
            region_id: "64",
            hc_key: "ru-yn",
            short: "Ямал",
            region: "Ямало-Ненецкий автономный округ",
            y: "10",
            x: "2",
            mh_city_id: "423",
            population: "459078",
            source: "ГТРК Ямал",
          },
          {
            region_id: "65",
            hc_key: "ru-ga",
            short: "Р.Алт",
            region: "Республика Алтай",
            y: "12",
            x: "6",
            mh_city_id: "1000",
            population: "64504",
            source: "ГТРК Горный Алтай",
          },
          {
            region_id: "66",
            hc_key: "ru-bu",
            short: "Бур",
            region: "Республика Бурятия",
            y: "14",
            x: "4",
            mh_city_id: "438",
            population: "582600",
            source: "ГТРК Бурятия",
          },
          {
            region_id: "67",
            hc_key: "ru-tu",
            short: "Тыва",
            region: "Республика Тыва",
            y: "13",
            x: "5",
            mh_city_id: "1080",
            population: "179471",
            source: "ГТРК Тыва",
          },
          {
            region_id: "68",
            hc_key: "ru-kk",
            short: "Хак",
            region: "Республика Хакасия",
            y: "13",
            x: "4",
            mh_city_id: "522",
            population: "372203",
            source: "ГТРК Хакасия",
          },
          {
            region_id: "69",
            hc_key: "ru-al",
            short: "Алт.к",
            region: "Алтайский край",
            y: "12",
            x: "5",
            mh_city_id: "195",
            population: "1312993",
            source: "ГТРК Алтай",
          },
          {
            region_id: "70",
            hc_key: "ru-ct",
            short: "Заб",
            region: "Забайкальский край",
            y: "14",
            x: "5",
            mh_city_id: "446",
            population: "720040",
            source: "ГТРК Чита",
          },
          {
            region_id: "71",
            hc_key: "ru-ky",
            short: "Крас",
            region: "Красноярский край",
            y: "13",
            x: "2",
            mh_city_id: "64",
            population: "2217054",
            source: "ГТРК Красноярск",
          },
          {
            region_id: "72",
            hc_key: "ru-ir",
            short: "Ирк",
            region: "Иркутская область",
            y: "14",
            x: "3",
            mh_city_id: "196",
            population: "1853799",
            source: "ГТРК Иркутск",
          },
          {
            region_id: "73",
            hc_key: "ru-ke",
            short: "Кем",
            region: "Кемеровская область",
            y: "13",
            x: "3",
            mh_city_id: "204",
            population: "2266449",
            source: "ГТРК Кузбасс",
          },
          {
            region_id: "74",
            hc_key: "ru-ns",
            short: "Ново",
            region: "Новосибирская область",
            y: "12",
            x: "4",
            mh_city_id: "179",
            population: "2207966",
            source: "ГТРК Новосибирск",
          },
          {
            region_id: "75",
            hc_key: "ru-om",
            short: "Омск",
            region: "Омская область",
            y: "11",
            x: "5",
            mh_city_id: "183",
            population: "1388531",
            source: "ГТРК Иртыш",
          },
          {
            region_id: "76",
            hc_key: "ru-to",
            short: "Томск",
            region: "Томская область",
            y: "12",
            x: "3",
            mh_city_id: "202",
            population: "771542",
            source: "ГТРК Томск",
          },
          {
            region_id: "77",
            hc_key: "ru-ck",
            short: "Саха",
            region: "Республика Саха (Якутия)",
            y: "15",
            x: "2",
            mh_city_id: "519",
            population: "651070",
            source: "ГТРК Саха",
          },
          {
            region_id: "78",
            hc_key: "ru-ka",
            short: "Камч",
            region: "Камчатский край",
            y: "17",
            x: "2",
            mh_city_id: "474",
            population: "245188",
            source: "ГТРК Камчатка",
          },
          {
            region_id: "79",
            hc_key: "ru-pr",
            short: "Прим",
            region: "Приморский край",
            y: "16",
            x: "5",
            mh_city_id: "200",
            population: "1453396",
            source: "ГТРК Владивосток",
          },
          {
            region_id: "80",
            hc_key: "ru-kh",
            short: "Хаб",
            region: "Хабаровский край",
            y: "16",
            x: "3",
            mh_city_id: "198",
            population: "1068308",
            source: "ГТРК Дальневосточная",
          },
          {
            region_id: "81",
            hc_key: "ru-am",
            short: "Амур",
            region: "Амурская область",
            y: "15",
            x: "3",
            mh_city_id: "261",
            population: "530465",
            source: "ГТРК Амур",
          },
          {
            region_id: "82",
            hc_key: "ru-mg",
            short: "Маг",
            region: "Магаданская область",
            y: "16",
            x: "2",
            mh_city_id: "394",
            population: "133607",
            source: "ГТРК Магадан",
          },
          {
            region_id: "83",
            hc_key: "ru-sl",
            short: "Схлн",
            region: "Сахалинская область",
            y: "17",
            x: "4",
            mh_city_id: "270",
            population: "400322",
            source: "ГТРК Сахалин",
          },
          {
            region_id: "84",
            hc_key: "ru-yv",
            short: "Евр",
            region: "Еврейская автономная область",
            y: "15",
            x: "4",
            mh_city_id: "466",
            population: "106873",
            source: "ГТРК Бира",
          },
          {
            region_id: "85",
            hc_key: "ru-2485",
            short: "Чук",
            region: "Чукотский автономный округ",
            y: "16",
            x: "1",
            mh_city_id: "9999",
            population: "35242",
            source: "ГТРК Чукотка",
          },
        ],
      },
      toggle_map_type: true,
      toggle_news_type: 0,
      toggle_period: 3,
      pageData: {
        title: "Интернет-показатели",
        type: "special-internet",
      },
      hideCityTypeBlock: false,
    };
  },
  async mounted() {
    if (!this.hasPrivGtrk) {
      this.$router.push("/");
    }
    await this.getMapData();
    //await this.initSlider();
  },

  methods: {
    async getNewsByTag(tag) {
      if (tag == "ТОП") {
        tag = "";
      }
      await this.getNewsData(tag);
    },
    makeRegionStr() {
      if (this.selected_geo > 0) {
        return this.options.tvRegionsPreData.find(
          (el) => el.region_id == this.selected_geo
        ).region;
      }
      return "";
    },
    initSlider() {
      this.ranges = [-30, -11, 11, 30];
      const slider = document.getElementById("comparison_slider");
      noUiSlider.create(slider, {
        start: CONSTS.TV_COMPARE_SLIDER_RANGES,
        margin: 1,
        connect: [true, true, true, true, true],
        orientation: "horizontal",
        behaviour: "tap-drag",
        step: 1,
        tooltips: true,
        format: wNumb({
          decimals: 0,
          suffix: "%",
          edit: function (value) {
            if (parseInt(value) > 0) {
              value = "+" + value;
            }
            return value;
          },
        }),
        range: {
          min: -100,
          max: 100,
        },
      });
      var connect = slider.querySelectorAll(".noUi-connect");
      var classes = [
        "c-1-color",
        "c-2-color",
        "c-3-color",
        "c-4-color",
        "c-5-color",
      ];

      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }

      slider.noUiSlider.on("update", (values) => {
        values.forEach((item, i) => {
          this.ranges[i] = parseInt(item);
        });
        this.$forceUpdate();
      });
    },
    async hideTip() {
      await this.$store.dispatch("saveSettings", {
        compare_period_tip_closed: 1,
      });
    },
    toExcel() {},
    saveChartToPng() {},
    // eslint-disable-next-line no-unused-vars
    async getNewsData(tag = "") {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      req.append("type", "gtrk");
      if (tag) {
        req.append("tag", tag);
      }
      this.news_data = [];
      let res = await axios.post("special/internetNews", req);
      if (res && res.data) {
        this.news_data = res.data;
      }
    },
    async getNewsCitiesData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      req.append("type", "gtrk");
      let res = await axios.post("special/internetNewsCities", req);
      this.cities = [];
      this.city_types = [];
      if (res && res.data) {
        this.cities = res.data.cities;
        this.city_types = res.data.city_types;
      }
    },
    async getTagsData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      req.append("type", "gtrk");
      let res = await axios.post("special/internetTags", req);
      this.tagsData = [];
      if (res && res.data) {
        this.tagsData = res.data;
      }
    },
    async getDevicesData() {
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("geo", this.selected_geo);
      req.append("type", "gtrk");
      let res = await axios.post("special/internetDevices", req);
      this.devices = {
        devices: [],
        mobile: [],
        tablet: [],
      };
      if (res && res.data) {
        this.devices = res.data;
      }
    },
    async getMapData() {
      this.bookmarks = [];
      this.currentBookmark = 0;
      this.regionStr = "";
      this.loading = true;
      const req = new FormData();
      req.append("period", this.periodStr);
      req.append("type", "gtrk");
      let res = await axios.post("special/internet", req);
      this.mapData = [];
      this.options.tvRegions = [];
      let preData = [];
      if (this.selected_geo > 0) {
        this.regionStr = this.makeRegionStr();
      }
      for (let i = 0; i < this.options.tvRegionsPreData.length; i++) {
        let value = 0;
        let koeff = 0;
        let reg = res.data.find(
          (el) => el.region_id == this.options.tvRegionsPreData[i].region_id
        );
        if (reg) {
          value = reg.value;
          koeff = reg.koeff;
        }
        this.options.tvRegions.push({
          value: value,
          x: Number(this.options.tvRegionsPreData[i].x),
          koeff: koeff,
          region_id: this.options.tvRegionsPreData[i].region_id,
          y: Number(this.options.tvRegionsPreData[i].y),
          hc_key: this.options.tvRegionsPreData[i].hc_key,
          short: this.options.tvRegionsPreData[i].short,
          mh_city_id: this.options.tvRegionsPreData[i].mh_city_id,
          region: this.options.tvRegionsPreData[i].region,
        });
        // if (this.options.tvRegions[i].hc_key != "ru-cn")
        preData.push([
          this.options.tvRegions[i].hc_key,
          this.options.tvRegions[i].value,
        ]);
      }
      preData.sort();
      this.mapData = preData;
      await this.getNewsData();
      await this.getNewsCitiesData();
      await this.getDevicesData();
      await this.getTagsData();
      this.loading = false;
      setTimeout(() => {
        //this.initSlider();
      });

      //console.log(this.mapData);
    },
  },
  watch: {
    currentBookmark(val) {
      this.getNewsByTag(this.bookmarks[val]);
    },
    region_chip(val) {
      if (val == 0) {
        this.selected_geo = -1;
        this.getMapData();
      }
    },
    toggle_period() {
      this.getMapData();
    },
  },
  computed: {
    SPECIAL_INTERNET_COLORS() {
      return CONSTS.SPECIAL_INTERNET_COLORS;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    periodStr() {
      switch (this.toggle_period) {
        case 1:
          return "yesterday";
        case 2:
          return "week";
        case 3:
          return "month";
        case 4:
          return "year";
      }
      return "today";
    },
    hasPrivGtrk() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.section_gtrk
      ];
    },
    pcPercent() {
      return (
        (this.devices.devices.find((el) => el.type == "pc")?.value ?? 0) * 100
      ).toFixed(0);
    },
    mobilePercent() {
      return (
        (this.devices.devices.find((el) => el.type == "mobile")?.value ?? 0) *
        100
      ).toFixed(0);
    },
    tabletPercent() {
      return (
        (this.devices.devices.find((el) => el.type == "tablet")?.value ?? 0) *
        100
      ).toFixed(0);
    },

    tagsChart() {
      const that = this;
      return {
        chart: {
          height: "50%",
          x: 0,
          y: 0,
          marginLeft: 0,
          marginTop: 0,
        },
        plotOptions: {
          wordcloud: {
            minFontSize: 8,
            maxFontSize: 15,
            point: {
              events: {
                click: function (event) {
                  if (that.bookmarks.length == 0) {
                    that.bookmarks.push("ТОП");
                  }
                  if (!that.bookmarks.includes(event.point.name)) {
                    that.bookmarks.push(event.point.name);
                    that.currentBookmark = that.bookmarks.length - 1;
                  } else {
                    let index = that.bookmarks.indexOf(event.point.name);
                    that.currentBookmark = index;
                  }
                },
              },
            },
          },
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        series: [
          {
            type: "wordcloud",
            data: that.tagsData,
            name: "Количество",
          },
        ],
        title: {
          text: "",
        },
      };
    },
    newsDevices() {
      let news = this.news_data;
      news = news.sort((a, b) => {
        return b.count - a.count;
      });
      const series = [
        {
          data: news.map((el) => {
            return { y: el.pc, page: el.page };
          }),
          name: "Компьютеры",
          color: CONSTS.SPECIAL_INTERNET_COLORS[0],
        },
        {
          data: news.map((el) => {
            return { y: el.tablet, page: el.page };
          }),
          name: "Планшеты",
          color: CONSTS.SPECIAL_INTERNET_COLORS[1],
        },
        {
          data: news.map((el) => {
            return { y: el.mobile, page: el.page };
          }),
          name: "Смартфоны",
          color: CONSTS.SPECIAL_INTERNET_COLORS[2],
        },
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = news.map((el) => {
        return el.news;
      });
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (30 + 18) + 120,
          stacking: "percent",
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: -6,
            y: 4,
            style: {
              color: "#000",
              fontSize: "14px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "30px",
            },
            formatter: function () {
              let news = this.value;
              if (news.length > 80) {
                news = news.slice(0, 80) + "...";
              }
              return `&nbsp;&nbsp;${news}`;
            },

            events: {
              click: function () {
                const pos = this.pos;
                if (news[pos].page) {
                  window.open(news[pos].page, "_blank").focus();
                }
              },
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          opposite: true,
          reversedStacks: false,
          title: { text: "" },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `
              ${this.x}<br>
              ${this.series.name}: <b>${this.y}%</b>
            `;
          },
        },
        plotOptions: {
          bar: {
            stacking: "percent",
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
          },
          series: {
            point: {
              events: {
                click: function () {
                  if (this.page) {
                    window.open(this.page, "_blank").focus();
                  }
                },
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 30,
          },
        },
        series: series,
      };
    },

    newsShare() {
      let news = [];
      news = [...this.news_data];
      news = news.sort((a, b) => {
        return b.count - a.count;
      });
      const series = [
        {
          data: news.map((el) => {
            return { y: el.count, page: el.page };
          }),
          name: "Количество просмотров",
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, CONSTS.CHART_BAR_COLORS[1]],
              [1, CONSTS.CHART_BAR_COLORS[0]],
            ],
          },
        },
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = [];
      categories = news.map((el) => {
        return el.news;
      });
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (30 + 18) + 120,
          stacking: false,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories: categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: -6,
            y: 4,
            style: {
              color: "#000",
              fontSize: "14px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "30px",
            },
            formatter: function () {
              let news = this.value;
              if (news.length > 80) {
                news = news.slice(0, 80) + "...";
              }
              return `&nbsp;&nbsp;${news}`;
            },
            events: {
              click: function () {
                const pos = this.pos;
                if (news[pos].page) {
                  window.open(news[pos].page, "_blank").focus();
                }
              },
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          opposite: true,
          reversedStacks: false,
          title: { text: "" },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `
              ${this.x}<br>
              Показы: <b>${this.y}</b>
            `;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            stacking: false,
          },
          series: {
            point: {
              events: {
                click: function () {
                  if (this.page) {
                    window.open(this.page, "_blank").focus();
                  }
                },
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 30,
          },
        },
        series: series,
      };
    },
    topCities() {
      let cities = this.cities;
      cities = cities.filter((el) => el.value > 0);
      let sumCities = 0;
      let count = 11;
      if (this.selected_geo > 0) {
        count = 10;
      }
      cities = cities.sort((a, b) => b.value - a.value);
      let result_cities = [];
      cities.forEach((el) => {
        if (--count > 0) {
          sumCities += Number(el.value);
          result_cities.push(el);
        }
      });
      cities = result_cities;
      if (sumCities < 1 && this.selected_geo > 0) {
        cities.push({
          type: "другие города",
          value: (1 - sumCities).toFixed(2),
        });
      }
      cities = cities.sort((a, b) => b.value - a.value);

      const channelsNum = cities.length;

      let series = [
        {
          data: cities.map((el) => {
            return { y: Number(el.value) * 100 };
          }),
          name: "Доля",
        },
      ];

      const chartMax = Math.max(...series[0].data.map((s) => s.y));
      let colors = new Array(channelsNum).fill(CONSTS.TOP20_CHANNELS_COLORS);

      return {
        chart: {
          type: "column",
          events: {},
          height: this.heightChart,
        },
        title: { text: "" },
        colors,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          categories: cities.map((el) => el.type),
          labels: {
            formatter: function () {
              let str = this.value;
              return str;
            },
            rotation: -60,
          },
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: "Доля" },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          column: {
            colorByPoint: true,
          },
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            point: {
              events: {
                click: function () {},
              },
            },
            stacking: false,
            dataLabels: { enabled: false },
          },
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>Доля:</b></br>{point.y:.2 f}%",
        },
        series: series,
      };
    },

    cityTypes() {
      let mobData = this.city_types
        .filter((el) => el.value > 0)
        .sort((a, b) => b.value - a.value);
      let sumCities = 0;
      mobData.forEach((el) => {
        sumCities += Number(el.value);
      });
      if (sumCities < 1) {
        mobData.push({
          type: "прочее",
          value: (1 - sumCities).toFixed(2),
        });
      }
      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) };
          }),
          name: "Доля",
          color: "#1976D2",
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)",
        },
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 60,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px",
            },
            formatter: function () {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false,
          },
          title: { text: "", visible: false },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            return `
              ${this.x}<br>
              Доля: <b>${this.y}</b>
              
            `;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            stacking: "percent",
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 1,
          },
        },
        series: series,
      };
    },

    smartphonesShare() {
      let mobData = this.devices.mobile
        .filter((el) => el.value > 0)
        .sort((a, b) => b.value - a.value);
      let max = Math.max(...mobData.map((o) => o.value));
      if (max == 0) max = 1;

      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) / max };
          }),
          name: "Доля",
          color: "#1976D2",
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) / max };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)",
        },
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 120,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px",
            },
            formatter: function () {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * max * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false,
          },
          title: { text: "", visible: false },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let percent = this.y * max * 100;
            percent = percent.toFixed(0);
            return `
              ${this.x}<br>
              Доля: <b>${percent}%</b>
            `;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            stacking: "percent",
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 1,
          },
        },
        series: series,
      };
    },
    tabletsShare() {
      let mobData = this.devices.tablet
        .filter((el) => el.value > 0)
        .sort((a, b) => b.value - a.value);
      let max = Math.max(...mobData.map((o) => o.value));
      if (max == 0) max = 1;
      const series = [
        {
          data: mobData.map((el) => {
            return { y: Number(el.value) / max };
          }),
          name: "Доля",
          color: "#1976D2",
        },
        {
          data: mobData.map((el) => {
            return { y: 1 - Number(el.value) / max };
          }),
          name: "Доля",
          color: "rgba(0, 0, 0, 0.12)",
        },
      ];
      // eslint-disable-next-line no-unused-vars
      let categories = mobData.map((el) => el.type);
      return {
        chart: {
          marginLeft: 10,
          type: "bar",
          events: {},
          height: series[0].data.length * (5 + 18) + 120,
        },
        title: { text: "" },
        colors: CONSTS.CHART_COLORS,
        subtitle: { text: "" },
        legend: { enabled: false },
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 0,
          categories,
          labels: {
            enabled: true,
            align: "left",
            rotation: 0,
            x: 0,
            y: -8,
            style: {
              color: "rgba(0, 0, 0, 0.6);",
              fontSize: "12px",
              width: "700px",
              overflow: "hidden",
              lineHeight: "20px",
            },
            formatter: function () {
              const data = this.value.split("|");
              const index = this.axis.categories.indexOf(this.value);
              let percent = "";
              if (index > -1) {
                console.log();
                percent = series[0].data[index].y * max * 100;
                percent = percent.toFixed(0);
              }
              return `${data[0]}, ${percent} %`;
            },
            events: {
              mouseover: function () {
                this.axis.chart.series[0].onMouseOver();
              },
            },
          },
        },
        yAxis: {
          gridLineWidth: 0,
          opposite: true,
          reversedStacks: false,
          labels: {
            visible: false,
            enabled: false,
          },
          title: { text: "", visible: false },
        },
        tooltip: {
          useHTML: true,
          formatter() {
            let percent = this.y * max * 100;
            percent = percent.toFixed(0);
            return `
              ${this.x}<br>
              Доля: <b>${percent}%</b>

              `;
          },
        },
        plotOptions: {
          bar: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            stacking: "percent",
          },
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            dataLabels: {
              enabled: false,
            },
            pointWidth: 1,
          },
        },
        series: series,
      };
    },

    drawTileMapData() {
      return this.options.tvRegions;
    },
    drawChartData() {
      return this.mapData;
    },
    drawChartOptionsTiles() {
      const that = this;
      ////console.log(this.mapData)
      return {
        chart: {
          type: "tilemap",
          inverted: true,
          height: "50%",
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        title: {
          text: "",
        },
        xAxis: {
          visible: false,
        },

        yAxis: {
          visible: false,
        },

        colorAxis: {
          min: 0,
          max: 1,
          stops: [
            [0, "rgba(205, 205, 205, 0.5)"],
            [0.00001, "rgba(241, 0, 0, 0.5)"],
            [1 + that.ranges[0] / 100, "rgba(241, 0, 0, 0.5)"],
            [1 + that.ranges[1] / 100, "rgba(245, 135, 60, 0.5)"],
            [1, "rgba(251, 227, 149, 0.5)"],
            [1 + that.ranges[2] / 100, "rgba(185, 210, 68, 0.5)"],
            [1 + that.ranges[3] / 100, "rgba(54, 175, 75, 0.5)"],
          ],
          visible: false,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "<b>{point.region}</b></br>{point.koeff:.2 f}",
        },
        plotOptions: {
          tilemap: {
            cursor: "pointer",
            point: {
              events: {
                click: function (event) {
                  that.selected_geo = event.point.region_id;
                  console.log(that.selected_geo);
                  that.getMapData();
                },
              },
            },
            pointPadding: 0,
            tileShape: "square",
            dataLabels: {
              enabled: true,
              format: "{point.short}",
              color: "#000000",
              style: {
                textOutline: false,
              },
              events: {
                click: function () {
                  that.selected_geo = this.point.region_id;
                  console.log(that.selected_geo);
                  that.getMapData();
                },
              },
            },
          },
        },
        series: [
          {
            name: "",
            data: that.drawTileMapData,
          },
        ],
      };
    },
    drawChartOptionsMap() {
      const that = this;
      ////console.log(this.mapData)
      return {
        chart: {
          height: "50%",
        },
        title: {
          text: "  ",
        },
        exporting: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "",
          formatter: function () {
            const region = that.options.tvRegions.find(
              (region) => region.hc_key === this.point.properties["hc-key"]
            );

            return `<b>${region.region}</b><br /> ${region.koeff}`;
          },
          // pointFormat: "<b>{point.region}</b></br>{point.value:.3 f}",
          // pointFormat: "{point.value:.3 f}",
          shared: true,
        },
        plotOptions: {
          series: {
            events: {
              click: function (event) {
                let hc_key;
                if (event.point.properties)
                  hc_key = event.point.properties["hc-key"];
                else if (event.target.point)
                  hc_key = event.target.point["hc-key"];

                const region = that.options.tvRegions.find(
                  (el) => el.hc_key === hc_key
                );
                that.selected_geo = region.region_id;
                that.getMapData();
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
        colorAxis: {
          min: 0,
          max: 1,
          stops: [
            [0, "rgba(205, 205, 205, 0.5)"],
            [0.00001, "rgba(241, 0, 0, 0.5)"],

            [1 + that.ranges[0] / 100, "rgba(241, 0, 0, 0.5)"],
            [1 + that.ranges[1] / 100, "rgba(245, 135, 60, 0.5)"],
            [1, "rgba(251, 227, 149, 0.5)"],
            [1 + that.ranges[2] / 100, "rgba(185, 210, 68, 0.5)"],
            [1 + that.ranges[3] / 100, "rgba(54, 175, 75, 0.5)"],
          ],
          visible: false,
        },

        series: [
          {
            // Use the gb-all map with no data as a basemap
            mapData: mapMarkup,
            data: that.mapData,
            borderColor: "#FFFFFF",
            nullColor: "rgba(220,220,220, 1)",
            showInLegend: false,
            cursor: "pointer",
            name: "Доля телесмотрения",
            dataLabels: {
              enabled: true,
              formatter: function () {
                //console.log(this.point);
                let point = this.point.properties["hc-key"];
                if (that.drawTileMapData.length > 0) {
                  return that.drawTileMapData.find(
                    (region) => region.hc_key === point
                  ).short;
                }
                return "";
                //
              },
            },
          },
        ],
      };
    },
    disableField() {
      return false;
    },
  },
};
</script>

<style lang="scss">
.device-percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
}
</style>
`
